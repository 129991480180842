import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getAnnouncementList,deleteAnnouncement} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function AnnouncementListing(props) {

    const [announcementList, setAnnouncementList] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getAnnouncementList({}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setAnnouncementList(resposnse.data.data);
            }else{
                setAnnouncementList([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeAn').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAnnouncement = ()=>{
        let path ="/CreateAnnouncement"
        navigate(path)
    }

    function deleteAnnouncementIdWise(id){
        console.log("Call Delete"+id)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteAnnouncement({"announcement_id":id}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeAn').DataTable().row("#anId"+id).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }
    if (announcementList==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Manage Announcement"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAnnouncement}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeAn" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>School Id</th>
                                    <th>Class Id</th>
                                    <th>Division Id</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>School Id</th>
                                    <th>Class Id</th>
                                    <th>Division Id</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            announcementList && announcementList.map((item,index)=>{
                                    return(
                                        <tr id={"anId"+item.announcement_id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.announcement_id}</td>
                                            <td>{item.title}</td>
                                            <td>{item.description}</td>
                                            <td>{item.school_id}</td>
                                            <td>{item.class_id}</td>
                                            <td>{item.division_id}</td>
                                            <td className="text-center" width="25%">
                                                <img src={item.announcement_image_full_path} width="98%" height="98%"/>
                                            </td>
                                            <td className="text-right">
                                            <div className='action-btn'>                        
                                            <Link to={`/editAnnouncement`}
                                                  state={{ data: item }}
                                                  className="btn btn-default col-green"><i className="zmdi zmdi-edit"></i></Link>
                                            <a onClick={()=> {deleteAnnouncementIdWise(item.announcement_id)}} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>
                                            </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
