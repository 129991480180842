
const S3_BUCKET ='cybersmarties-prod';
const REGION ='eu-west-1';
const ACCESS_KEY ='AKIA4R7PQJRBQ6VL42UW';
const SECRET_ACCESS_KEY ='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';

  export function uploadSchoolImages(){
    return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/school",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function uploadAdminImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/admin",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function uploadAvatarImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/avatar",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function uploadFunZoneImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/fun_zone",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function uploadDivisionCoverImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/division/cover",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function uploadDivisionLogoImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/division/logo",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }


    export function uploadHomeWorkImages(){
      return {
        bucketName: S3_BUCKET,
        dirName:"cybersmarties/homework",
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
       }
    }

    export function getMainBucketPath(){
      return "https://cybersmarties-prod.s3.eu-west-1.amazonaws.com/cybersmarties/"
    }
    
    export function getSchoolBucketPath(){
      return "school/"
    }

    export function getAdminBucketPath(){
      return "admin/"
    }

    export function getAvatarBucketPath(){
      return "avatar/"
    }


    export function getFunZoneBucketPath(){
      return "fun_zone/"
    }


    export function getDivisionLogoPath(){
      return "division/logo/"
    }

    export function getDivisionCoverPath(){
      return "division/cover/"
    }

    export function getHomeWorkPath(){
      return "homework/"
    }
