import { axiosClient } from "./apiClient";

export function signInAdmin(data){
    return axiosClient.post('/api/admin/admin/user_login', data);
}

export function getDeshboardData(){
    return axiosClient.post('/api/admin/common/get_dashboard_counts'); 
}

export function getAssignSchool(data){
    return axiosClient.post('/api/admin/admin/get_assigned_schools',data)
}

export function getSchooldDetails(data){
    return axiosClient.post('/api/admin/school/get_school_detail', data);
}

export function getClassList(data){
    return axiosClient.post('/api/admin/class/get_class_list', data);
}

export function getDevisionList(data){
    return axiosClient.post('/api/admin/division/get_division_list', data);
}

export function getClassDetails(data){
    return axiosClient.post('/api/admin/class/get_class_detail', data);    
}

export function createDevision(data) {
    return axiosClient.post('/api/admin/division/add_division', data);    
}

export function editDevision(data) {
    return axiosClient.post('/api/admin/division/update_division', data);        
}

export function getDevisionDetails(data){
    return axiosClient.post('/api/admin/division/get_division_detail', data);    
}

export function deleteDevision(data){
    return axiosClient.post('/api/admin/division/delete_division', data);    
}

export function createUser(data){
    return axiosClient.post('/api/admin/user/add_user',data); 
}


export function createBulkUser(data){
    return axiosClient.post('/api/admin/user/add_bulk_student',data); 
}

export function editUser(data){
    return axiosClient.post('/api/admin/user/update_user',data); 
}

export function getUserList(data){    
    return axiosClient.post('/api/admin/user/get_user_list', data);
}

export function deleteUser(data){
    return axiosClient.post('/api/admin/user/delete_user',data); 
}

export function updateUserStatus(data){
    return axiosClient.post('/api/admin/user/update_user_status',data); 
}

export function getUserDetails(data){
    return axiosClient.post('/api/admin/user/get_user_detail', data);    
}

export function getSchoolList(data){
    return axiosClient.post('/api/admin/school/get_school_list', JSON.stringify(data));
}

export function getAnnouncementList(data){
    return axiosClient.post('/api/admin/announcement/announcement_list', JSON.stringify(data));
}

export function deleteAnnouncement(data){
    return axiosClient.post('/api/admin/announcement/announcement_delete', JSON.stringify(data));
}

export function getAdminList(data){
    return axiosClient.post('/api/admin/admin/get_admin_list',data); 
}

export function createAdmin(data){
    return axiosClient.post('/api/admin/admin/add_admin',data); 
}

export function getAdminDetails(data){
    return axiosClient.post('/api/admin/admin/get_admin_detail',data); 
}

export function editAdmin(data){
    return axiosClient.post('/api/admin/admin/update_admin',data); 
}

export function getSurveyList(data){
    return axiosClient.post('/api/admin/survey/survey_list',data); 
}

export function createSurvey(data){
    return axiosClient.post('/api/admin/survey/create_survey',data); 
}

export function deleteSurvey(data){
    return axiosClient.post('/api/admin/survey/delete_survay',data); 
}

export function surveyDetailsAPI(data){
    return axiosClient.post('/api/admin/survey/get_user_survey_response',data); 
}

export function setBoundries(data){
    return axiosClient.post('/api/admin/user/set_boundries',data); 
}

export function getBoundries(data){
    return axiosClient.post('/api/admin/user/get_boundries',data); 
}

export function createAnnouncement(data){
    return axiosClient.post('/api/admin/announcement/announcement_add',data)
}

export function editAnnouncement(data){
    return axiosClient.post('/api/admin/announcement/announcement_update',data)
}