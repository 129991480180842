import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams,useLocation} from 'react-router-dom'
import { surveyDetailsAPI } from '../../api/apiHandler';
import Alert from '../../components/Alert/Alert';
import $ from 'jquery'; 

export default function SurveyDetails(props) {
    let navigate = useNavigate(); 

    const [surveyUserListing, setSurveyUserListing] = useState(null)

    const { state } = useLocation();

    const [surveyDetails, setSurveyDetailsDetails] = useState(state.data)
    
    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        surveyDetailsAPI({"survey_id":state.data.id}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setSurveyUserListing(resposnse.data.data);
            }else{
                setSurveyUserListing([]);
            }
        })
        return () => mounted = false;
    }, []);

    $(document).ready(function () {
        $('#mangeS').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    if (surveyUserListing==null ) return null;

    props.isLoader(false)

    return (
        <section className="content home">   
        <div className="container-fluid"> 
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 m-t-15">
                    <div className="card">
                        <div className="header">
                        <h2>Survey Details</h2>
                        </div>
                        <div className="body">
                        <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="official" aria-expanded="true">
                            <li className="list-group-item"><b className="mr-2">ID : </b> {surveyDetails.id}</li>
                            <li className="list-group-item"><b className="mr-2">Title : </b> {surveyDetails.title}</li>
                            <li className="list-group-item"><b className="mr-2">Description : </b> {surveyDetails.description}</li>
                            <li className="list-group-item"><b className="mr-2">Option : </b> {surveyDetails.option1}<br/>{surveyDetails.option2}<br/>{surveyDetails.option3}<br/>{surveyDetails.option4}<br/></li>
                            <li className="list-group-item"><b className="mr-2">School Id: </b> {surveyDetails.school_id}</li>
                            <li className="list-group-item"><b className="mr-2">School Name: </b> {surveyDetails.school_name}</li>
                            <li className="list-group-item"><b className="mr-2">Class Id: </b> {surveyDetails.class_id}</li>
                            <li className="list-group-item"><b className="mr-2">Class Name: </b> {surveyDetails.class_name}</li>
                            <li className="list-group-item"><b className="mr-2">Division ID: </b> {surveyDetails.division_id}</li>
                            <li className="list-group-item"><b className="mr-2">Division Name: </b> {surveyDetails.division_name}</li>
                            <li className="list-group-item"><b className="mr-2">Total Attend User : </b> {surveyDetails.total_attend_user}</li>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    </div>
                    <div className="body">
                        <table id="mangeS" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>User Id</th>
                                    <th>User Name</th>
                                    <th>User Answer</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>User Id</th>
                                    <th>User Name</th>
                                    <th>User Answer</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            surveyUserListing && surveyUserListing.map((item,index)=>{
                                    return(
                                        <tr id={"sId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.user_id}</td>
                                            <td>{item.username}</td>
                                            <td>{item.user_answer}</td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            </div>
        </div>
     </section>
    );
}