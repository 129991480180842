import React, { useState, useEffect } from "react";
import Modal from "react-modal";
//Datatable Modules
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Heading from "../../components/Heading/Heading";
import { Link, useNavigate } from "react-router-dom";
import { getAssignSchool, setBoundries,getBoundries } from "../../api/apiHandler";

export default function SchoolManagementListing(props) {
  const [schoolList, setSchoolList] = useState(null);
  const [popup, setPopUp] = useState(false);
  const [selectedSchoolId, setSchoolId] = useState(null)
  const [friendListBoundrey,setFriendListBoundrey] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  useEffect(() => {
    props.isLoader(true);
    var adminId = localStorage.getItem("SAid", 0);
    getAssignSchool({ admin_id: adminId }).then((resposnse) => {
      if (resposnse.data.code == 1) {
        setSchoolList(resposnse.data.data.list);
      } else {
        setSchoolList([]);
      }
    });
  }, []);
  const clickSubmitButton = (e) => {
    e.preventDefault();
    const valueBoundrie = $(
      "input[type='radio'][name='group1']:checked"
    ).val();
    let values = {
      school_id: selectedSchoolId,
      friend_list_boundary: valueBoundrie
    }
    setBoundries(values).then((response) => {
      if(response.data.code == 1){
        closeModal()
        setFriendListBoundrey("")
      }else{
        console.log("Response is : ", response.data)
      }
    })
  };

  const openModal = (id) => {
    getBoundries({"school_id":id}).then((response) => {
      console.log("Response is : ", response.data)
      if(response.data.code == 1){
        if(response.data.data==undefined){
          setFriendListBoundrey("")
          setPopUp(true);  
        }else{
          console.log("Data Selected",response.data.data.friend_list_boundary)
          setFriendListBoundrey(response.data.data.friend_list_boundary)
          setPopUp(true);  
        }
      }else{
        console.log("Response is : ", response.data)
      }
    })
  };
  
  const closeModal = () => {
    setPopUp(false);
  };

  const changePopupData=(e)=>{
    console.log("Change Popup Data",e)
    const valueBoundrie = $(
      "input[type='radio'][name='group1']:checked"
    ).val();
    setFriendListBoundrey(valueBoundrie)
  }

  $(document).ready(function () {
    $("#mangeUser").DataTable({
      pagingType: "full_numbers",
      pageLength: 10,
      processing: true,
      order: [[0, "desc"]],
      dom: "Bfrtip",
      buttons: ["copy", "csv", "print"],
      bDestroy: true,
    });
  });

  if (schoolList == null) return null;
  props.isLoader(false);
  return (
    <section className="content home" style={{ zIndex: 1 }}>
      <Heading title="Manage School / Division / Student / Teacher" />
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="header"></div>
              <div className="body">
                <table
                  id="mangeUser"
                  className="table table-bordered table-striped table-hover dataTable js-exportable"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>

                  {
                    <tbody>
                      {schoolList.map((item, index) => {
                        return (
                          <tr id={"studentId" + item.school_id}>
                            <td>{item.school_id}</td>
                            <td>{item.school_name}</td>
                            <td className="text-right">
                              <div className="action-btn">
                                <Link
                                  to={`/SchoolDetails/`}
                                  state={{ schoolId: item.school_id }}
                                  className="btn btn-default col-green"
                                >
                                  <i className="zmdi zmdi-eye"></i>
                                </Link>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    console.log("Selected school is : ", item)
                                    setSchoolId(item?.school_id)
                                    openModal(item?.school_id)
                                  }}
                                  className="btn btn-default col-green"
                                >
                                  Set Permission / Boundaries
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={popup}
        className={"content home"}
        onRequestClose={closeModal}
        style={{
          content: {
            height: "50%",
            width: "50%",
            backgroundColor: "#FFFFFF",
            justifyContent: "center",
          },
          overlay: {
            position: "absolute",
            zIndex: 3,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#00000050",
          },
        }}
      >
        <label htmlFor="name_en">Set Permission / Boundaries</label>
        <form
          id="form_validation"
          method=""
          onSubmit={clickSubmitButton}
          action="#"
        >
          <div className="form-group d-flex">
            <div className="demo-radio-button">
              <input
                name="group1"
                type="radio"
                id="radio_english"
                onChange={changePopupData}
                className="radio-col-light-blue"
                value="class"
                checked={friendListBoundrey=="class"}
                required
              />
              <label for="radio_english">Class</label>
              <input
                name="group1"
                type="radio"
                id="radio_arabic"
                onChange={changePopupData}
                className="radio-col-light-blue"
                value="division"
                checked={friendListBoundrey=="division"}
                required
              />
              <label for="radio_arabic">Division</label>
              <input
                name="group1"
                type="radio"
                onChange={changePopupData}
                id="radio_turkish"
                className="radio-col-light-blue"
                value="school"
                checked={friendListBoundrey=="school"}
                required
              />
              <label for="radio_turkish">School</label>
            </div>
            <button
              className="btn btn-raised btn-primary waves-effect"
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </Modal>
    </section>
  );
}
