import React,{useEffect} from 'react'
import { Link } from "react-router-dom";
import {logOutRedirectCall} from "../../../pages/Common/RedirectPathMange"

export default function SideBar() {

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
       }
    
       useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, []);

  return (
    <aside id="leftsidebar" className="sidebar">
    <div className="user-info">
        <div className="info-container">
            <div className="name" data-toggle="dropdown">{localStorage.getItem("SAname")}</div>
            <div className="email">{localStorage.getItem("SAemail")}</div>
        </div>
    </div>
    <div className="menu">
        <ul className="list">
            <li><Link to="/"><i className="zmdi zmdi-delicious"></i><span>Dashboard</span> </Link> </li>
            <li><Link to="/SchoolManagementListing"><i className="zmdi zmdi-city-alt"></i><span>Manage School</span></Link></li>             
            <li><Link to="/ManageAnnouncement"><i className="zmdi zmdi-city-alt"></i><span>Manage Announcement</span></Link></li>             
            <li><Link to="/SurveyListing"><i className="zmdi zmdi-city-alt"></i><span>Manage Survey</span></Link></li>             
            <li> <a onClick={()=>{
                logOutRedirectCall()
            }}><i className="zmdi zmdi-power-off"></i><span>Sign Out</span> </a> </li>
            <li><div id="google_translate_element"></div></li>
        </ul>
    </div>
</aside>    
  )
}