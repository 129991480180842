
export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/signIn" 
//    window.open(path,'mywindow').focus()
    window.location.href = path;
  }

export function homeRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/"
//    window.open(path,'mywindow').focus()
    window.location.href = path;  
}

export function logOutRedirectCall(){
  localStorage.removeItem("SAisLogin");
  localStorage.removeItem("SAuserData");
  localStorage.removeItem("SAid");
  localStorage.removeItem("SAtoken");
  localStorage.removeItem("SAname");
  localStorage.removeItem("SAemail");
  localStorage.removeItem("SAschoolList");
  localStorage.removeItem("SAschoolListId");

  loginRedirectCall()
}

export function loginRedirectCallWithDataStore(data){
  console.log("Redirect Call")
  console.log(data)
  localStorage.setItem("SAisLogin",true);
  localStorage.setItem("SAuserData",JSON.stringify(data));
  localStorage.setItem("SAid",data.id);
  localStorage.setItem("SAtoken",data.token);
  localStorage.setItem("SAname",data.name);
  localStorage.setItem("SAemail",data.email);
  homeRedirectCall()
}


  