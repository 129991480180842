import React,{useEffect,useState,useCallback} from 'react'
import Heading from '../../components/Heading/Heading';
import { useParams,useNavigate} from "react-router-dom";
import {getDeshboardData,getAssignSchool} from '../../api/apiHandler'


export default function Dashboard(props) {

    const [myDashooBoardData, setDashooBoardData] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        var adminId = localStorage.getItem("SAid",0);
        getAssignSchool({"admin_id":adminId}).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                var listSchoolId=[]
                resposnse.data.data.list.forEach(element => {
                    listSchoolId.push(element.school_id)
                });
                localStorage.setItem("SAschoolList",JSON.stringify(resposnse.data.data.list));
                localStorage.setItem("SAschoolListId",JSON.stringify(listSchoolId));
            }
        }); 
        getallDetails();
        return () => mounted = false;
    }, []);

    const getallDetails = async() => {
        const getDetails = await getDeshboardData();
        if (typeof getDetails.data.data !== 'undefined') setDashooBoardData(getDetails.data.data);
    }
    if (myDashooBoardData==null) return null;

  return (
    <section className="content home">
        <Heading title="Dashboard"/>
        <div className="container-fluid">
        <div className="row clearfix social-widget">
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect facebook-widget">
                    <div className="icon"><i className="zmdi zmdi-accounts"></i></div>
                    <div className="content">
                        <div className="text">Total Student</div>
                        <div className="number">{myDashooBoardData.total_student}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect instagram-widget">
                    <div className="icon"><i className="zmdi zmdi-accounts"></i></div>
                    <div className="content">
                        <div className="text">Total Teacher</div>
                        <div className="number">{myDashooBoardData.total_teacher}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect twitter-widget">
                    <div className="icon"><i className="zmdi zmdi-graduation-cap"></i></div>
                    <div className="content">
                        <div className="text">Total schools</div>
                        <div className="number">{myDashooBoardData.total_school}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect google-widget">
                    <div className="icon"><i className="zmdi zmdi-library"></i></div>
                    <div className="content">
                        <div className="text">Total Class</div>
                        <div className="number">{myDashooBoardData.total_class}</div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                <div className="card info-box-2 hover-zoom-effect linkedin-widget">
                    <div className="icon"><i className="zmdi zmdi-library"></i></div>
                    <div className="content">
                        <div className="text">Total Division</div>
                        <div className="number">{myDashooBoardData.total_division}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
