import './App.css';
import NavBar from './components/NavBar/NavBar';
import Loader from './components/Loding/Loder';

import Dashboard from './pages/Dashboard/Dashboard';

import SchoolManagementListing from './pages/SchoolManagement/SchoolManagementListing';
import SchoolDetails from './pages/SchoolManagement/SchoolDetails';
import CrteateDevision from './pages/SchoolManagement/CrteateDevision';
import DivisionDetails from './pages/SchoolManagement/DivisionDetails';

import StudentDetails from './pages/UserListing/StudentDetails';
import CreateStudent from './pages/UserListing/CreateStudent';
import CreateStudentBulk from './pages/UserListing/CreateStudentBulk';
import TeacherDetails from './pages/UserListing/TeacherDetails';
import CreateTeacher from './pages/UserListing/CreateTeacher';

import { Route, Routes } from "react-router-dom";
import { useState } from 'react';

import SignIn from './pages/Auth/SignIn';
import RedirectBlankPage from './pages/Common/RedirectBlankPage';
import RedirectBlankPageHome from './pages/Common/RedirectBlankPageHome';
import AnnouncementListing from './pages/Announcement/AnnouncementListing';
import CreateAnnouncement from './pages/Announcement/CreateAnnouncement';

import SurveyListing from './pages/Survey/SurveyListing';
import CreateSurvey from './pages/Survey/CreateSurvey';
import SurveyDetails from './pages/Survey/SurveyDetails';


function App() {
  const [isLoading, setIsLoading] = useState(false);

  const showLoder =(isLoading)=>{
    setIsLoading(isLoading)
}

const getFlow = ()=>{

  if(!localStorage.getItem("SAisLogin",false)){
    return (
      <>
     <Loader isActive={isLoading}/>
    <Routes>
    <Route path="/signIn" element={<SignIn type="e" isLoader={showLoder}/>}/>
    <Route path="*" element={<RedirectBlankPage />}/>
    </Routes>
      </>
    )
  }
  else{
    return (
      <>
     <Loader isActive={isLoading}/>
    <NavBar />
    <Routes>
    <Route path="/" element={<Dashboard isLoader={showLoder}/>} />
    <Route path="/SchoolManagementListing" element={<SchoolManagementListing isLoader={showLoder}/>} />
    <Route path="/SchoolDetails" element={<SchoolDetails  isLoader={showLoder}/>}/>
    <Route path="/createDivision/:schoolId" element={<CrteateDevision type="c" isLoader={showLoder}/>}/>
    <Route path="/editDivision/:schoolIdDivID" element={<CrteateDevision type="e" isLoader={showLoder}/>}/>
    <Route path="/divisionDetails/:divId" element={<DivisionDetails isLoader={showLoder}/>}/>

    <Route path="/studentDetails/:userId" element={<StudentDetails isLoader={showLoder}/>} />
    <Route path="/createStudent" element={<CreateStudent type="c" isLoader={showLoder}/>} />
    <Route path='/createStudentBulk/:schoolId' element={<CreateStudentBulk type="c" isLoader={showLoder}/>} />
    <Route path="/editStudent/:userId" element={<CreateStudent type="e" isLoader={showLoder}/>} />
    
    <Route path="/teacherDetails/:userId" element={<TeacherDetails isLoader={showLoder} role="teacher"/>} />
    <Route path="/createTeacher/:schoolId" element={<CreateTeacher role="teacher" type="c" isLoader={showLoder}/>} />
    <Route path="/editTeacher/:userId" element={<CreateTeacher role="teacher" type="e" isLoader={showLoder}/>} />

    <Route path="/ManageAnnouncement" element={<AnnouncementListing isLoader={showLoder}/>} />
    <Route path="/CreateAnnouncement" element={<CreateAnnouncement isLoader={showLoder}/>} />
    <Route path="/editAnnouncement" element={<CreateAnnouncement type="e" isLoader={showLoder}/>} />

    
    <Route path="/SurveyListing" element={<SurveyListing isLoader={showLoder}/>} />
    <Route path="/createSurvey" element={<CreateSurvey type="c" isLoader={showLoder}/>} />
    <Route path="/SurveyDetails" element={<SurveyDetails isLoader={showLoder}/>} />
      
    <Route path="*" element={<RedirectBlankPageHome />}/>
    </Routes>
      </>
    )
  }
}

  return (
    <>
    {getFlow()}
    </>
    );
}

export default App;
