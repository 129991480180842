import React, { useState ,useEffect} from 'react'
import { Link,useNavigate,useParams } from 'react-router-dom'
import {getSurveyList,deleteSurvey} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
//Datatable Modules
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from 'jquery'; 
import Heading from '../../components/Heading/Heading';

export default function SurveyListing(props) {

    const [surveyListing, setSurveyListing] = useState(null)

    useEffect(() => {
        let mounted = true;
        props.isLoader(true)
        getSurveyList({"page":"1","limit":"1000","sort_by":"id","order_by":"desc"}).then((resposnse)=>{
            console.log(resposnse)
            if(resposnse.data.code==1){
                setSurveyListing(resposnse.data.data);
            }else{
                setSurveyListing([]);
            }
        })
        return () => mounted = false;
    }, []);


    $(document).ready(function () {
        $('#mangeS').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 10,
                  order: [[0, 'desc']],
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ],
                      "bDestroy": true
            }
        );
    });

    let navigate = useNavigate(); 

    const redirectCreateAnnouncement = ()=>{
        let path ="/createSurvey"
        navigate(path)
    }

    function deleteSurveyIdWise(Id){
        console.log("Call Delete"+Id)
        if(window.confirm("Are you sure you want to delete?")){
            props.isLoader(true)
            deleteSurvey({"survey_id":Id}).then((resposnse)=>{
                console.log(resposnse);
                if(resposnse.data.code==1){
                    $('#mangeS').DataTable().row("#sId"+Id).remove().draw();
                }else{
                }
                props.isLoader(false)
            });
        }
    }


    if (surveyListing==null) return null;
    props.isLoader(false)

    return (
    <section className="content home">
        <Heading title="Manage Survey"/>
        <div className="container-fluid"> 
        <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                    <div className="header">
                    <button type="button" className="btn btn-raised btn-primary m-t-15 m-b-15 waves-effect right" onClick={redirectCreateAnnouncement}>Create</button>
                    </div>
                    <div className="body">
                        <table id="mangeS" className="table table-bordered table-striped table-hover dataTable js-exportable">
                            <thead>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Option</th>
                                    <th>School</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Total Attend User</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Created Date</th>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Option</th>
                                    <th>School</th>
                                    <th>Class</th>
                                    <th>Division</th>
                                    <th>Total Attend User</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>

                            { 
                            surveyListing && surveyListing.map((item,index)=>{
                                    return(
                                        <tr id={"sId"+item.id}>
                                            <td>{item.created_at}</td>
                                            <td>{item.id}</td>
                                            <td>{item.title}</td>
                                            <td>{item.description}</td>
                                            <td>{item.option1}<br/>{item.option2}<br/>{item.option3}<br/>{item.option4}<br/></td>
                                            <td>{item.school_name}</td>
                                            <td>{item.class_name}</td>
                                            <td>{item.division_name}</td>
                                            <td>{item.total_attend_user}</td>
                                            <td className="text-right">
                                                <div className='action-btn'>  
                                                    <a onClick={()=>deleteSurveyIdWise(item.id)} className="btn btn-default col-red"><i className="zmdi zmdi-delete"></i></a>                      
                                                    <Link to={`/SurveyDetails`} state={{"data":item}} className="btn btn-default col-green"><i className="zmdi zmdi-eye"></i></Link>
                                                </div>    
                                            </td>
                                        </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    );
}
