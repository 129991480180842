import React, { useState,useEffect } from 'react'
import { useParams ,useNavigate,useLocation} from 'react-router-dom'
import {createAnnouncement,editAnnouncement,getAssignSchool,getClassList,getDevisionList} from '../../api/apiHandler'
import Alert from '../../components/Alert/Alert';
import {uploadHomeWorkImages,getMainBucketPath,getHomeWorkPath} from '../../AWS/MyS3'
import S3 from 'react-aws-s3';
import Select from 'react-select';
import $ from 'jquery';


export default function CreateAnnouncement(props) {
    var id=""
    var announcementDetails=null
    let navigate = useNavigate(); 
    const { state } = useLocation();
    let isEditFirstTimeSetDataSchool=""    
    let isEditFirstTimeSetDataClassDiviosn=""

    
    if(props.type=="e"){
     id=state.data.announcement_id 
     announcementDetails=state.data                      
     console.log("Edit Data Check Conditions",announcementDetails)
    }

    window.Buffer = window.Buffer || require("buffer").Buffer;
    const ReactS3Client = new S3(uploadHomeWorkImages());

    const initalValue= {title:"",description:""}
    const [formValues,setFormValues]= useState(initalValue)
    const [selectedAnnouncementsFile, setAnnouncements] = useState(null);

    const [selectedSchool, setSelectedSchool] = useState([]);

    const [classList, setClasslList] = useState([])
    const [devisionList, setDevisionList] = useState([])

    const [classAndDevisionList, setClassAndDevisionList] = useState([])
    const [selecteClassDevisiondOptionSchool, setClassDevisionOption] = useState(null);
    const [schoolList, setSchoolList] = useState(null);

    const [msg,setMsg] = useState (null);


    const handleChange = (e)=>{
        if(e.target.value.trim()==""){
            e.target.value=e.target.value.trim()      
        }
        const {name,value} =e.target;
        setFormValues({...formValues,[name]:value});
    }

    const handleSchoolChange=(data)=>{
        console.log(data)
        setSelectedSchool(data)
        geClassListApi(data.value)
    }

    const handleClassDiviosnChange=(data)=>{
        console.log(data)
        setClassDevisionOption(data)
    }

    const handleFileProfileInput = (e) => {
        props.isLoader(true)
        const file = e.target.files[0];
        console.log(file)
        
        const newFileName = new Date().getTime()+e.target.files[0].name;
        ReactS3Client
        .uploadFile(file, newFileName)
        .then(data => {
            console.log(data)
            props.isLoader(false)
            if (data.status === 204) {
                setAnnouncements(newFileName)
              }
        })
        .catch(err =>  {
            props.isLoader(false)
            console.log("Error on s3")
            console.error(err)})
    }



    const clickSubmitButton = (e)=>{
        e.preventDefault();

    if(props.type=="e"){
        updateApiCall(formValues)
    }else{
        createApiCall(formValues)
    }

    }

    const createApiCall = (initValues) =>{
        console.log("Create Api call")
        console.log(initValues)
        props.isLoader(true)

        let schoolId=""

        let classId=""
        let devisionId=""

        if(selectedSchool!==null){
            schoolId=selectedSchool.value
        }

        if(selecteClassDevisiondOptionSchool!==null){
            classId=selecteClassDevisiondOptionSchool.value.split(",")[0]
            devisionId=selecteClassDevisiondOptionSchool.value.split(",")[1]
        }

        
        let apiData={"title":initValues.title,"description":initValues.description,"class_id":classId,"division_id":devisionId,"school_id":schoolId,"image":selectedAnnouncementsFile}

        console.log(apiData)

        createAnnouncement(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });                
    }

    const updateApiCall = (initValues) =>{
        console.log("Edit Api call")

        let schoolId=""

        let classId=""
        let devisionId=""

        if(selectedSchool!==null){
            schoolId=selectedSchool.value
        }

        if(selecteClassDevisiondOptionSchool!==null){
            classId=selecteClassDevisiondOptionSchool.value.split(",")[0]
            devisionId=selecteClassDevisiondOptionSchool.value.split(",")[1]
        }

        
        let apiData={"announcement_id":announcementDetails.announcement_id,"title":initValues.title,"description":initValues.description,"class_id":classId,"division_id":devisionId,"school_id":schoolId,"image":selectedAnnouncementsFile}

        console.log(apiData)

        editAnnouncement(apiData).then((resposnse)=>{
            props.isLoader(false)
            console.log(resposnse)
            if(resposnse.data.code==1){
                navigate(-1)
            }
        });
    } 

    const geClassListApi = (id)=>{
        getClassList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            
            if(resposnse.data.code==1){
                setClasslList(resposnse.data.data.list);
                geDevisionListApi(id,resposnse.data.data.list)
            }else{
                setClasslList([])
                geDevisionListApi(id,[])
            }
        })
    }

    const geDevisionListApi = (id,classListData)=>{
        getDevisionList({"page":"1","limit":"1000","sort_by":"id","order_by":"asc","school_id":id}).then((resposnse)=>{
            if(resposnse.data.code==1){
                setDevisionList(resposnse.data.data.list);
                setDataGroup(classListData,resposnse.data.data.list)
            }else{
                setDevisionList([]);
                setDataGroup([],[])
            }
        })
    }


    useEffect(() => {
        let mounted = true;
        if(props.type=="e"){
            let data= {title: announcementDetails.title,description:announcementDetails.description} 
            setFormValues(data);    
            isEditFirstTimeSetDataSchool="a"
            isEditFirstTimeSetDataClassDiviosn="a"


            let profileImage = announcementDetails.announcement_image_full_path
            .replace(getMainBucketPath(), "")
            .replace(getHomeWorkPath(), "");

          $("#profileImage").next(".custom-file-label").html(profileImage);

          if (!(profileImage === "")) {
            $("#profileImage").prop("required", false);
          }

          setAnnouncements(profileImage)

        }

        var adminId = localStorage.getItem("SAid",0);
        getAssignSchool({"admin_id":adminId}).then((resposnse)=>{
            console.log("School list")
            console.log(resposnse.data)
            if(resposnse.data.code==1){
                let list =[]
                resposnse.data.data.list.map((item, index) => {
                    console.log({label:item.name,value:item.id})
                    list.push({label:item.school_name,value:item.school_id})
                })
                setSchoolList(list)
                setSelectedSchool(list[0])

                if(isEditFirstTimeSetDataSchool=="a"){
                    geClassListApi(announcementDetails.school_id)
                }else{
                    geClassListApi(list[0].value)
                }

                if(isEditFirstTimeSetDataSchool==="a"){
                    console.log("Again not call")
                    isEditFirstTimeSetDataSchool=""
                    let selectData =list.filter((item)=> {return item.value === announcementDetails.school_id})
                    console.log(selectData)
                    setSelectedSchool(selectData[0])
                }

                console.log("Edit School","Data",props.type)

                if(props.type==="e"){
                    console.log("Edit School","Data")
                    }

            }else{

            }
        });

        return () => mounted = false;
    }, []);

    $(document).ready(function() {
        $('input[type="file"]').on("change", function() {
          let filenames = [];
          let files = this.files;
          if (files.length > 1) {
            filenames.push("Total Files (" + files.length + ")");
          } else {
            for (let i in files) {
              if (files.hasOwnProperty(i)) {
                filenames.push(files[i].name);
              }
            }
          }
          $(this)
            .next(".custom-file-label")
            .html(filenames.join(","));
        });
      });


    const setDataGroup = (classListData,devisionListData)=>{
        let groupData=[]
        classListData.forEach( (item)=>{
            
            let options=[]
            devisionListData.filter( devision => devision.class_id===item.id).forEach((subItem)=>{
                options.push({label:subItem.name,value:item.id+","+subItem.id})
            })

            if(options.length>0){
                var data={label:item.name,options:options}
                groupData.push(data) 
            }
        })

        if(groupData.length>0){
            var selectData=groupData[0].options[0]
            setClassAndDevisionList(groupData)
            setClassDevisionOption(selectData)
        }else{
            setClassAndDevisionList(groupData)
            setClassDevisionOption(null)
        }


        if(isEditFirstTimeSetDataClassDiviosn==="a"){
            console.log("Again not call Divison")
            isEditFirstTimeSetDataClassDiviosn=""
            
            let selectData = []
            groupData.forEach((item)=>{
                item.options.forEach((subItem)=>{
                    console.log(subItem.value)
                    console.log((announcementDetails.class_id+","+announcementDetails.division_id))
                    if(subItem.value===(announcementDetails.class_id+","+announcementDetails.division_id)){
                        selectData.push(subItem)
                    }
                })
            })
            console.log(selectData)
            setClassDevisionOption(selectData[0])
        }

        props.isLoader(false)
    }


  return (
    <section className="content">
        <Alert alert={msg}/>      

    <div className="container-fluid"> 
        <div className="row clearfix ">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card m-t-15">
                    <div className="header">
                        <h2>{ (props.type == "e") ? "Edit Announcement" : "Create Announcement"}</h2>
                    </div>
                    <div className="body">
                    <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                    <label htmlFor="name">Title</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <input type="text" placeholder='Title' className="form-control" id="title"
                                                   name="title" required value={formValues.title}
                                                   onChange={handleChange}/>
                                        </div>
                                    </div>
                                    <label htmlFor="name">Description</label>
                                    <div className="form-group">
                                        <div className="form-line">
                                            <textarea type="text" placeholder='Description' className="form-control" id="description"
                                                   name="description" required value={formValues.description}
                                                   onChange={handleChange}  rows="4" />
                                        </div>
                                    </div>
                                    <label htmlFor="school_log">Announcement Image</label>
                                    <div className="form-group">
                                            <div className="custom-file">
                                                <input type="file" name="files[]" className="custom-file-input form-control" id="profileImage" required onChange={handleFileProfileInput} accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                            </div>
                                    </div>

                                    <label htmlFor="name_en">Select School</label>
                                    <div className="form-group">
                                    <Select
                                                   value={selectedSchool}
                                                    onChange={handleSchoolChange}
                                                    options={schoolList}
                                                    name="selectSchoolData" 
                                                    isDisabled={props.type=="e"}

                                    />
                                    <input name="selectSchool"  className='dropHideInput' value={ (selectedSchool == null)? "" : ""+selectedSchool.value  } id="selectSchool" type="text" required/>            

                                    </div>

                                    <label htmlFor="name_en">Select Class And Division</label>
                                    <div className="form-group">
                                    <Select
                                                    value={selecteClassDevisiondOptionSchool}
                                                    onChange={handleClassDiviosnChange}
                                                    options={classAndDevisionList}
                                                    name="selectClassAndDevisionList"
                                                    isDisabled={props.type=="e"}
                                                 />
                                     <input name="classAndDevision"  className='dropHideInput' value={ (selecteClassDevisiondOptionSchool == null)? "" : ""+selecteClassDevisiondOptionSchool.value  } id="classAndDevision" type="text" required/>            

                                    </div>

                            <button className="btn btn-raised btn-primary waves-effect" type="submit">SUBMIT</button>
                        </form>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
